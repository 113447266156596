'use client';
import React, { useState } from 'react';

import { Primary } from 'stories/Button.stories';
import { BsCalendar2, BsClock } from 'react-icons/bs';

import { DateTimeFormatter } from '@utils/utils';
import cn from 'classnames';

function CustomStripBanner({ campaignData = null }) {
  const [showStrip, setShowStrip] = useState(true);

  const closeStrip = () => setShowStrip(false);

  if (!campaignData) return <></>;

  const { header, description, dateAndTime, buttonName, ctaLink } = campaignData;

  return (
    <>
      {showStrip && (
        <section
          className={cn(
            `sticky top-[3.9rem] z-40 w-full overflow-hidden bg-gradient-to-r from-[#232323] to-[#4a4a4a] py-[16px] px-[16px]`,
            { 'md:pl-0 md:pr-[24px]': !!header, 'px-[24px]': !header }
          )}
        >
          <div className="grid items-center gap-y-[16px] gap-x-[8px] md:grid-cols-12">
            <div
              className={cn('flex flex-col items-center gap-[12px] md:flex-row', {
                'md:col-span-7': !!dateAndTime && !!buttonName,
                'md:!col-span-12': !dateAndTime && !buttonName,
                'md:col-span-9': !dateAndTime || !buttonName,
              })}
            >
              {header && (
                <span
                  className="w-32 self-start rounded-sm py-[4px] px-[8px] text-center font-satoshi-medium text-[12px] font-semibold leading-4 text-white md:self-center"
                  style={{ backgroundColor: '#684AA7' }}
                >
                  {header}
                </span>
              )}

              <p className="pr-[16px] font-satoshi-bold text-[18px] leading-6 text-white">
                {description}
              </p>
            </div>

            <div
              className={cn('grid grid-cols-2 items-center md:grid-cols-5', {
                'md:col-span-5': !!dateAndTime && !!buttonName,
                'md:!col-span-0': !dateAndTime && !buttonName,
                'md:col-span-3': !dateAndTime || !buttonName,
              })}
            >
              {dateAndTime && (
                <div
                  className={cn(
                    'flex items-center gap-x-[4px] place-self-start bg-[#4a4a4a] pr-[8px] sm:gap-x-[8px] md:col-span-3',
                    { 'md:!col-span-5': !buttonName }
                  )}
                >
                  <span className="h-9 w-1 -translate-x-1/2 transform  rounded-md bg-red sm:w-0.5" />
                  <div className="flex items-center gap-x-2 text-xs text-white sm:gap-x-6 md:text-sm">
                    <p className="flex items-center gap-x-2 font-satoshi-medium">
                      <span className="hidden sm:block">
                        <BsClock />
                      </span>
                      {DateTimeFormatter(dateAndTime, 'h:mm A')}
                    </p>

                    <p className="flex items-center gap-x-[8px]">
                      <span className="hidden sm:block">
                        <BsCalendar2 />
                      </span>
                      {DateTimeFormatter(dateAndTime, '', true)}
                    </p>
                  </div>
                </div>
              )}

              {buttonName && (
                <div
                  className={cn('place-self-end md:col-span-2 md:mr-[32px]', {
                    '!place-self-start md:!col-span-5 md:!place-self-end': !dateAndTime,
                  })}
                >
                  <Primary
                    linkCTA
                    label={buttonName}
                    size={'small'}
                    href={ctaLink}
                    target={'_blank'}
                  />
                </div>
              )}
            </div>

            <button
              className="absolute place-self-end self-start font-satoshi-bold text-2xl !leading-3 text-white md:text-3xl"
              onClick={closeStrip}
            >
              &times;
            </button>
          </div>
        </section>
      )}
    </>
  );
}

export default CustomStripBanner;
