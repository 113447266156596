import axios from 'axios';

const apiBaseUrl = process.env.NEXT_PUBLIC_BYTES_STRAPI_API_URL;

export const getBytesStrapiUrl = () => apiBaseUrl;

export const getArticles = async ({
  page = 1,
  size = 9,
  subject,
  order = 'desc',
  updatedAt = false,
}) => {
  let str = '';
  if (subject && subject !== 'all') {
    str += `&filters[subjects][name][$eq]=${encodeURIComponent(subject)}`;
  }
  if (page) {
    str += `&pagination[page]=${page}`;
  }
  if (size) {
    str += `&pagination[pageSize]=${size}`;
  }
  if (updatedAt) {
    str += `&sort[0]=createdAt:${order}`;
  } else {
    str += `&sort[0]=views:${order}`;
  }
  return await axios.get(
    `${apiBaseUrl}/api/articles?populate=body,heroImage,description,author.name,author.designation,author.thumbnail,tags.name,subjects.name&${str}`
  );
};
